import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../AuthContext";
import "../styles/Cart.css";
import { FaArrowLeft } from "react-icons/fa";
import { HiOutlineShoppingBag } from "react-icons/hi2";
import Loading from "./Loading";
import { useNavigate } from "react-router-dom";
import logos from "../assets/logo.png";

const Cart = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [cartItems, setCartItems] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(true);
  const { userData } = useContext(AuthContext);
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    street: "",
    country: "India",
    state: "",
    city: "",
    pincode: "",
  });

  const [isNewAddress, setIsNewAddress] = useState(false);

  const fetchAddresses = async () => {
    const userId = localStorage.getItem("userId");
    try {
      const response = await fetch(
        `https://api.lifesignify.com/api/auth/user/addresses/${userId}`
      );
      const data = await response.json();
      setAddresses(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error("Error fetching addresses:", error);
    }
  };

  useEffect(() => {
    fetchAddresses();
  }, []);

  useEffect(() => {
    if (userData && userData.email) {
      setFormData((prevData) => ({
        ...prevData,
        email: userData.email,
      }));
    }
  }, [userData]);

  const handleAddressSelect = (address) => {
    setIsNewAddress(false);
    setFormData(address);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    const { name, mobile, street, country, state, city, pincode } = formData;
    if (
      !name ||
      !mobile ||
      !street ||
      !country ||
      !state ||
      !city ||
      !pincode
    ) {
      alert("Please fill in all fields.");
      return;
    }
    navigate("/checkout", { state: { formData, products } });
  };

  useEffect(() => {
    const fetchCartItems = async () => {
      if (!userData) {
        alert("Please login first!");
        return;
      }

      try {
        const userId = localStorage.getItem("userId");
        const response = await fetch(
          `https://api.lifesignify.com/api/cart?userId=${userId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch cart items");
        }

        const data = await response.json();
        setCartItems(data.items);

        const productFetchPromises = data.items.map(async (item) => {
          const productId = item.productId;

          if (item.itemType === "Course") {
            const courseResponse = await fetch(
              `https://api.lifesignify.com/api/courses/${productId}`
            );
            if (!courseResponse.ok) throw new Error("Failed to fetch course");
            return await courseResponse.json();
          } else if (item.itemType === "Book") {
            const bookResponse = await fetch(
              `https://api.lifesignify.com/api/books/${productId}`
            );
            if (!bookResponse.ok) throw new Error("Failed to fetch book");
            return await bookResponse.json();
          } else if (item.itemType === "Dhyanam") {
            const dhyanamResponse = await fetch(
              `https://api.lifesignify.com/api/dhyanam/${productId}`
            );
            if (!dhyanamResponse.ok) throw new Error("Failed to fetch dhyanam");
            return await dhyanamResponse.json();
          }
        });

        const productsData = await Promise.all(productFetchPromises);
        setProducts(productsData.filter((product) => product));
      } catch (error) {
        console.error("Error fetching cart items:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCartItems();
  }, [userData]);

  const handleRemoveItem = async (productId) => {
    try {
      const userId = localStorage.getItem("userId");
      const response = await fetch(
        `https://api.lifesignify.com/api/cart/remove/${productId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
          body: JSON.stringify({ userId }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to remove item from cart");
      }

      const updatedCart = await response.json();
      setCartItems(updatedCart.items);
      setProducts((prevProducts) =>
        prevProducts.filter((product) => product._id !== productId)
      );
    } catch (error) {
      console.error("Error removing item from cart:", error);
    }
  };

  const calculateSubtotal = (products) => {
    return products.reduce((total, product) => {
      return (
        total +
        parseFloat(product.price.replace("₹", "").replace(",", "").trim())
      );
    }, 0);
  };

  const calculateTotal = (products) => {
    return calculateSubtotal(products);
  };

  return (
    <div className="cart-container">
      <div className="companyLogoDiv">
        <img src={logos} alt="Company Logo" className="comapny_logo" />
      </div>
      <div className="cartMainDivContainer">
        <div className="cart-left">
          <h2 className="customer-details">Select Address</h2>
          {addresses.map((address, index) => (
            <div key={index} className="address-option">
              <input
                type="radio"
                name="selectedAddress"
                onChange={() => handleAddressSelect(address)}
              />
              <p>
                {`${address.name}, ${address.mobile}, ${address.street}, ${address.city}, ${address.state}, ${address.country}, ${address.pincode}`}
              </p>
            </div>
          ))}
          <div className="headingAndButton">
            <h2 className="customer-details">
              {isNewAddress ? "Add Address" : "Selected Address Details"}
            </h2>
            <button
              type="button"
              onClick={() => setIsNewAddress(true)}
              className="add-address-button"
            >
              Add New Address
            </button>
          </div>

          <input
            type="text"
            placeholder="Name"
            className="input-full"
            name="name"
            value={formData.name}
            onChange={handleChange}
            disabled={!isNewAddress}
          />
          <input
            type="text"
            placeholder="Mobile Number"
            className="input-full"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            disabled={!isNewAddress}
          />
          <input
            type="text"
            placeholder="Street Address"
            className="input-full"
            name="street"
            value={formData.street}
            onChange={handleChange}
            disabled={!isNewAddress}
          />
          <input
            type="text"
            placeholder="City"
            className="input-full"
            name="city"
            value={formData.city}
            onChange={handleChange}
            disabled={!isNewAddress}
          />
          <input
            type="text"
            placeholder="State"
            className="input-full"
            name="state"
            value={formData.state}
            onChange={handleChange}
            disabled={!isNewAddress}
          />
          <input
            type="text"
            placeholder="Pincode"
            className="input-full"
            name="pincode"
            value={formData.pincode}
            onChange={handleChange}
            disabled={!isNewAddress}
          />

          <div className="button-row">
            <button type="button" className="back-button">
              <FaArrowLeft style={{ marginRight: 10 }} /> Back to Shop
            </button>
            <button
              type="button"
              className="continue-button"
              onClick={handleSubmit}
            >
              Continue to Payment
            </button>
          </div>
        </div>

        <div className="cart-right">
          <h2 className="cart-header">
            <HiOutlineShoppingBag size={28} className="cartIcon" />
            Cart
            <span>{products.length}</span>
          </h2>
          {loading ? (
            <Loading />
          ) : (
            <div className="cart-products">
              {products.map((product) => (
                <div className="product-item" key={product._id}>
                  <div className="product-image-container">
                    <img
                      src={product.image ?? product.imageUrl}
                      alt={product.title}
                      className="product-image"
                    />
                  </div>

                  <div className="product-details">
                    <h3>{product.title ?? product.name}</h3>
                    <div className="quantity-update">
                      <button className="quantity-button">-</button>
                      <input type="number" value="1" readOnly />
                      <button className="quantity-button">+</button>
                    </div>
                  </div>

                  <div className="product-remove-container">
                    <button
                      onClick={() => handleRemoveItem(product._id)}
                      className="remove-button"
                    >
                      ✖
                    </button>
                    <p className="product-price">₹{product.price}</p>
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className="coupon-section">
            <input
              type="text"
              placeholder="Coupon Code"
              className="input-full"
            />
            <button className="coupon-button">Apply</button>
          </div>
          <div className="total-section">
            <div>
              <h3>Subtotal</h3>
              <p>₹{calculateSubtotal(products)}</p>{" "}
              {/* Function to calculate subtotal */}
            </div>
            <div className="hrlINEfORtOTAL">
              <h3>Total</h3>
              <p>₹{calculateTotal(products)}</p>{" "}
              {/* Function to calculate total */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
