import React from "react";
import "../styles/Footer.css";
import logoImage from "../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import justdial from "../assets/justdial.png";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="footerContainer">
      <div className="scheduleConsultationButtonContainer">
        <div className="rectangle"></div>
        <button
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/get-a-consultation")}
        >
          Schedule a free consultation
        </button>
      </div>

      <div className="taglineWealthFuturesContainer">
        <div className="footer-column logo">
          <img src={logoImage} alt="Logo" className="footer-logo" />
        </div>
        <div className="taglineWealthFutures">
          <h1>Building wealth, creating futures.</h1>
          <p>
            Trusted financial consulting firm providing expert guidance and
            personalized solutions to help clients unlock their full financial
            potential.
          </p>
        </div>
      </div>

      <footer className="footer">
        <div className="footer-column">
          <h4 onClick={() => navigate("/personality-development")}>
            Personality Development
          </h4>
          <ul>
            <li onClick={() => navigate("/dmit")}>DMIT</li>
            <li onClick={() => navigate("/midbrain-activation")}>
              Midbrain Activation
            </li>
            <li onClick={() => navigate("/personality-development")}>
              Personality Development
            </li>
            <li onClick={() => navigate("/law-of-attraction")}>
              Law of Attraction
            </li>
          </ul>
        </div>
        <div className="footer-column" style={{ display: "none" }}>
          <h4>Financial Services</h4>
          <ul>
            <li onClick={() => navigate("/financial-planning")}>
              Financial Planning
            </li>
            <li onClick={() => navigate("/wealth-management")}>
              Wealth Management
            </li>
            <li>Tax and Estate Planning</li>
            <li>Industries</li>
          </ul>
        </div>
        <div className="footer-column footer-column-privacy">
          <h4>Privacy</h4>
          <ul>
            <li onClick={() => navigate("/terms-of-use")}>
              Terms and Conditions
            </li>
            <li onClick={() => navigate("/privacy-policy")}>Privacy Policy</li>
          </ul>
        </div>
        <div className="footer-column">
          <h4>Connect with Us</h4>
          <ul className="social-icons">
            <li>
              <a
                href="https://www.facebook.com/lifesignify"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook size={24} style={{ marginRight: 7 }} />
                Facebook
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/lifesignify/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram size={24} style={{ marginRight: 7 }} />
                Instagram
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/@LifeSignify"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaYoutube size={24} style={{ marginRight: 7 }} />
                YouTube
              </a>
            </li>
            <li>
              <a
                href="https://jsdl.in/RSL-MEE1726573238"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={justdial} alt="justdial" />
                Justdial
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-column map">
          <div className="map-placeholder">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3556.813276429955!2d80.99372919999999!3d26.9411334!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39995996128c7bdb%3A0xad79254d6d2368a0!2sLife%20Signify!5e0!3m2!1sen!2sin!4v1729743398193!5m2!1sen!2sin"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </footer>
      <div className="footerEnd">©️ 2024 Life Signify LLP.</div>
    </div>
  );
};

export default Footer;
