import React, { useState, useEffect, useContext } from "react";
import { LuShoppingCart } from "react-icons/lu";
import "../styles/Courses.css";
import Loading from "./Loading";
import { AuthContext } from "../AuthContext";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const Courses = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [courses, setCourses] = useState([]);
  const [loadingId, setLoadingId] = useState(null); // Track loading state for each course
  const { isLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate(); // Initialize navigate for routing

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await fetch("https://api.lifesignify.com/api/courses");
        if (!response.ok) {
          throw new Error("Failed to fetch courses");
        }
        const data = await response.json();
        setCourses(data);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    fetchCourses();
  }, []);

  const addToCart = async (courseId) => {
    const userChoice = window.confirm(
      "Do you want to add this course to the cart? Click Cancel to watch the demo."
    );

    if (!userChoice) {
      window.open(
        "https://1drv.ms/v/s!Ap4H7paVq1dZautUkuq0tAH0Kx0?e=QTX0tY",
        "_blank"
      );
      return;
    }

    if (!isLoggedIn) {
      alert("Please log in to add items to your cart.");
      return;
    }

    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    // Set the loading state
    setLoadingId(courseId);

    try {
      const itemType = "Course";
      const response = await fetch("https://api.lifesignify.com/api/cart/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          productId: courseId,
          quantity: 1,
          userId,
          itemType: itemType,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to add course to cart");
      }

      const cartData = await response.json();
      console.log(cartData);
      alert("Course added to cart successfully!");
      navigate("/cart");
    } catch (error) {
      console.error("Error adding to cart:", error);
    } finally {
      setLoadingId(null);
    }
  };
  return (
    <div className="coursesContainer">
      {courses.length > 0 ? (
        courses.map((course) => (
          <div className="courseDiv" key={course._id}>
            <img src={course.image} alt={course.name} className="courseImage" />
            <h2>{course.name}</h2>
            <hr />
            <div className="bottomOfCourse">
              <p>₹{course.price}</p>
              <button
                onClick={() => addToCart(course._id)}
                disabled={loadingId === course._id}
              >
                {loadingId === course._id ? (
                  <>Adding to cart...</>
                ) : (
                  <>
                    <LuShoppingCart
                      color="white"
                      size={16}
                      style={{ marginRight: 5 }}
                    />
                    Add to cart
                  </>
                )}
              </button>
            </div>
          </div>
        ))
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Courses;
