import React, { useEffect, useContext, useState } from "react";
import "../styles/Downloads.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { AuthContext } from "../AuthContext";
import axios from "axios";

const Downloads = () => {
  const { userData } = useContext(AuthContext);
  const [activeChapter, setActiveChapter] = useState(null);
  const [activeOptionIndex, setActiveOptionIndex] = useState(null);
  const [downloads, setDownloads] = useState([]);
  const [activeBook, setActiveBook] = useState(null);
  const [activeCourseIndex, setActiveCourseIndex] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchProductDetails = async (itemType, productId) => {
    try {
      let response;
      if (itemType === "Book") {
        response = await axios.get(
          `https://api.lifesignify.com/api/books/${productId}`
        );
      } else if (itemType === "Course") {
        response = await axios.get(
          `https://api.lifesignify.com/api/courses/${productId}`
        );
      }
      return response.data;
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    const fetchDownloads = async () => {
      try {
        const response = await fetch(
          `https://api.lifesignify.com/api/orders/user/${localStorage.getItem(
            "userId"
          )}`
        );
        const data = await response.json();

        console.log("Data", data);

        const ordersWithProductData = await Promise.all(
          data.orders
            .filter((order) => order.paymentStatus === "Paid")
            .map(async (order) => {
              const productDetails = await Promise.all(
                order.productId
                  .filter(
                    (product) =>
                      product.itemType === "Book" ||
                      product.itemType === "Course"
                  )
                  .map(async (product) => {
                    const productData = await fetchProductDetails(
                      product.itemType,
                      product.id
                    );
                    return productData;
                  })
              );
              return productDetails;
            })
        );

        const filteredDownloads = ordersWithProductData.flat().filter(Boolean);
        setDownloads(filteredDownloads);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchDownloads();
  }, []);

  const toggleChapter = (index) => {
    setActiveChapter(activeChapter === index ? null : index);
    setActiveOptionIndex(null);
  };

  const handleOptionClick = (chapterIndex, optionIndex) => {
    if (activeOptionIndex === optionIndex && activeChapter === chapterIndex) {
      setActiveOptionIndex(null);
    } else {
      setActiveOptionIndex(optionIndex);
      setActiveChapter(chapterIndex);
    }
  };

  const handleReadClick = (bookIndex) => {
    setActiveBook(activeBook === bookIndex ? null : bookIndex);
  };

  const handleCourseButtonClick = (courseIndex) => {
    setActiveCourseIndex(
      activeCourseIndex === courseIndex ? null : courseIndex
    );
  };
  console.log(downloads);

  return (
    <div className="downloadsContainer">
      <div className="downloadsMainContainer">
        {downloads.map(
          (download, index) =>
            download.itemType === "Course" && (
              <>
                <div key={index} className="courseItem">
                  <div className="courseItemLeft">
                    <img
                      src={download.image}
                      alt="course"
                      className="courseImage"
                    />
                  </div>
                  <div className="courseItemRight">
                    <p className="courseName">{download.name}</p>
                  </div>
                  <div className="courseItemRightButton">
                    <button
                      onClick={() => handleCourseButtonClick(index)}
                      className="showChaptersButton"
                    >
                      {activeCourseIndex === index ? "Hide" : "Show Chapters"}
                    </button>
                  </div>
                </div>
                {activeCourseIndex === index && (
                  <div className="chaptersContainer">
                    {download.chapters?.map((chapter, chapterIndex) => (
                      <div key={chapterIndex} className="chapterItem">
                        <div
                          className="chapterTitle"
                          onClick={() => toggleChapter(chapterIndex)}
                        >
                          {chapter.name}
                          {activeChapter === chapterIndex ? (
                            <FaChevronUp className="dropdownIcon" />
                          ) : (
                            <FaChevronDown className="dropdownIcon" />
                          )}
                        </div>
                        {activeChapter === chapterIndex && (
                          <div className="optionsList">
                            {chapter.subChapters?.map((option, optionIndex) => (
                              <p
                                key={optionIndex}
                                className="optionItem"
                                onClick={() =>
                                  handleOptionClick(chapterIndex, optionIndex)
                                }
                              >
                                {option.name}
                              </p>
                            ))}
                          </div>
                        )}
                        {activeOptionIndex !== null &&
                          activeChapter === chapterIndex && (
                            <div className="videoPlayer">
                              <iframe
                                src={
                                  chapter.subChapters[activeOptionIndex].link
                                }
                                width="100%"
                                height="500px"
                                allow="autoplay; fullscreen"
                                style={{ borderRadius: 5 }}
                              ></iframe>
                            </div>
                          )}
                      </div>
                    ))}
                  </div>
                )}
              </>
            )
        )}
        {downloads.map(
          (download, index) =>
            download.itemType === "Book" && (
              <>
                <div key={index} className="courseItem">
                  <div className="courseItemLeft">
                    <img
                      src={download.imageUrl}
                      alt="course"
                      className="courseImage"
                    />
                  </div>
                  <div className="courseItemRight">
                    <p className="courseName">{download.title}</p>
                  </div>
                  <div className="courseItemRightButton">
                    <button onClick={() => handleReadClick(index)}>
                      {activeBook === index ? "Close" : "Read"}
                    </button>
                  </div>
                </div>
                {activeBook === index && (
                  <div className="videoPlayer">
                    <iframe
                      src={download.pdfLink}
                      width="100%"
                      height="500px"
                      allow="autoplay; fullscreen"
                      style={{ borderRadius: 5 }}
                    ></iframe>
                  </div>
                )}
              </>
            )
        )}
      </div>
    </div>
  );
};

export default Downloads;
