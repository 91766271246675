import React, { useEffect } from "react";
import { FaArrowLeft } from "react-icons/fa"; // Importing an arrow icon
import { HiOutlineShoppingBag } from "react-icons/hi2";
import "../styles/Checkout.css";
import { FaCreditCard } from "react-icons/fa6";
import { useLocation } from "react-router-dom";
import axios from "axios";
import logos from "../assets/logo.png";

const Checkout = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  const { formData, products } = location.state || {};

  const calculateSubtotal = (products) => {
    return products.reduce((total, product) => {
      return (
        total +
        parseFloat(product.price.replace("₹", "").replace(",", "").trim())
      );
    }, 0);
  };

  const calculateTotal = (products) => {
    return calculateSubtotal(products);
  };

  const handlePayment = () => {
    let userId = localStorage.getItem("userId");

    axios
      .post(
        "https://api.lifesignify.com/api/payment/phonepe",
        {
          formData,
          products,
          userId: userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        window.location.href = response.data.redirectUrl;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="cart-container">
      <div className="companyLogoDiv">
        <img src={logos} alt="Company Logo" className="comapny_logo" />
      </div>
      <div className="cartMainContainerDiv">
        <div className="cart-left">
          <div className="userEmailDiv">
            <div>
              <p>Contact</p>
              <p>{formData.email}</p>
            </div>
            <div className="editBtn">Edit</div>
          </div>
          <h2 className="customer-details">Payment</h2>

          <div className="paymentModeMain">
            <div className="paymentModeMainTop">
              <div>
                <div className="circleSelected">
                  <span className="innerCircle"></span>
                </div>
                <p>Pay with PhonePe</p>
              </div>
              <div>
                <img
                  style={{ borderRadius: "50%" }}
                  src="https://imgs.search.brave.com/mMT9ZH6zDoipm53wxqwq5v-lKbZo_WtLlCksUEK_mM4/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9sb2dv/cy1kb3dubG9hZC5j/b20vd3AtY29udGVu/dC91cGxvYWRzLzIw/MjEvMDEvUGhvbmVQ/ZV9Mb2dvLTcwMHg2/OTgucG5n"
                  alt="phonpeImg"
                />
              </div>
            </div>
            <div className="paymentModeMainBottom">
              <p>
                It uses UPI apps like BHIM, Paytm, Google Pay, PhonePe or any
                Banking UPI app to make payment.
              </p>
            </div>
          </div>

          <p className="termAndCondition">
            Your personal data will be used to process your order, support your
            experience throughout this website, and for other purposes described
            in our{" "}
            <span style={{ color: "red", cursor: "pointer" }}>
              privacy policy
            </span>
            .
          </p>
          <div className="button-row">
            <button className="back-button">
              <FaArrowLeft style={{ marginRight: 10 }} /> Back
            </button>
            <button onClick={handlePayment} className="continue-button">
              <FaCreditCard size={20} style={{ marginRight: 10 }} />
              Pay ₹{calculateTotal(products)}
            </button>
          </div>
        </div>

        <div className="cart-right">
          <h2 className="cart-header">
            <HiOutlineShoppingBag size={28} className="cartIcon" />
            Cart
            <span>{products.length}</span>
          </h2>
          <div className="cart-products">
            {products.map((product) => (
              <div className="product-item" key={product._id}>
                <div className="product-image-container">
                  <img
                    src={product.image ?? product.imageUrl}
                    alt={product.title ?? product.name}
                    className="product-image"
                  />
                </div>

                <div className="product-details">
                  <h3>{product.title ?? product.name}</h3>
                  <div className="quantity-update">
                    <button className="quantity-button">-</button>
                    <input type="number" value="1" readOnly />
                    <button className="quantity-button">+</button>
                  </div>
                </div>

                <div className="product-remove-container">
                  <button className="remove-button">✖</button>
                  <p className="product-price">₹{product.price}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="coupon-section">
            <input
              type="text"
              placeholder="Coupon Code"
              className="input-full"
            />
            <button className="coupon-button">Apply</button>
          </div>
          <div className="total-section">
            <div>
              <h3>Subtotal</h3>
              <p>₹{calculateSubtotal(products)}</p>
            </div>
            <div className="hrlINEfORtOTAL">
              <h3>Total</h3>
              <p>₹{calculateTotal(products)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
